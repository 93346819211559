<template>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <div>
            <a
                v-gtm:click="[
                    {
                        event: 'create_account',
                        eventData: {
                            email: formData.email,
                            requested_plan: formData['requested_plan_name'],
                            type: 'google',
                            source: 'create account page',
                        },
                    },
                ]"
                :href="`/accounts/bff/auth/sign-up/oauth?${oauthQueryParams}`"
                class="google-login__btn d-flex mx-auto
                align-items-center btn text-secondary px-3">
                <span class="mx-auto">
                    <img
                        alt="Google Logo"
                        :src="googleLogo"
                        class="google-login__logo">
                    <span class="ml-1">
                        Continue With Google
                    </span>
                </span>
            </a>
        </div>

        <div class="separator text-uppercase my-3">
            or
        </div>

        <div
            v-for="input in inputs"
            :key="input.name"
            class="form-group">
            <ValidationProvider
                v-slot="{ errors }"
                :rules="input.rules"
                :vid="input.name"
                :name="input.label"
                mode="eager">
                <label class="card__info-label mb-1 required">{{ input.label }}</label>
                <div class="input-group">
                    <input
                        v-model="formData[input.name]"
                        class="form-control"
                        :class="{ 'is-invalid' : errors.length }"
                        :type="isPasswordRevealed(input.name) ? 'text' : input.type">
                    <div
                        v-if="input.type === 'password'"
                        class="reveal-password">
                        <div
                            class="btn btn-outline-secondary"
                            @click="togglePasswordReveal(input.name)">
                            <span class="icon is-small is-right">
                                <i
                                    class="fas"
                                    :class="isPasswordRevealed(input.name) ? 'fa-eye-slash' : 'fa-eye'" />
                            </span>
                        </div>
                    </div>
                </div>

                <MessageValidationError
                    v-if="errors.length"
                    :msg="errors[0]">
                </MessageValidationError>
            </ValidationProvider>
        </div>

        <button
            v-gtm:click="[
                {
                    event: 'create_account',
                    eventData: {
                        email: formData.email,
                        requested_plan: formData['requested_plan_name'],
                        type: 'password',
                        source: 'create account page',
                    },
                },
            ]"
            class="btn btn-primary w-100 mt-4"
            type="button"
            :disabled="invalid || isSubmitting"
            @click="handleSubmit(submit)">
            <span v-if="isSubmitting">
                <i
                    aria-hidden="true"
                    class="fas fa-spinner fa-spin" /> Submitting...
            </span>
            <span v-else>
                Create an account
            </span>
        </button>

        <div class="mt-3 text-center login-prompt">
            Already have an account?
            <router-link
                :to="{ name: 'loginV2' }"
                class="font-weight-bold">
                Login
            </router-link>.
        </div>
    </ValidationObserver>
</template>

<script>
import { MessageValidationError } from '@technologyadvice/relay-frontend'
import auth from '@api/auth'
import googleLogo from '../../assets/imgs/logo/google-logo.svg'

export default {
    components: {
        MessageValidationError,
    },
    props: {
        formData: {
            type: Object,
            required: true,
        },
    },
    computed: {
        formDataQueryParams() {
            return Object.keys(this.formData)
                .map(key => `${key}=${this.formData[key]}`)
                .join('&')
        },
        redirectQueryParam() {
            return 'redirect=/clicks/products/create'
        },
        oauthQueryParams() {
            return `${this.redirectQueryParam}&${this.formDataQueryParams}`
        },
    },
    data() {
        return {
            googleLogo,
            isSubmitting: false,
            inputs: [
                {
                    label: 'Email',
                    name: 'email',
                    type: 'email',
                    rules: 'required|email',
                },
                {
                    label: 'Password',
                    name: 'password',
                    type: 'password',
                    rules: 'required|password',
                },
                {
                    label: 'Confirm Password',
                    name: 'password_confirmation',
                    type: 'password',
                    rules: 'required|confirmed:password',
                },
            ],
            revealedPasswordInputs: {
                password: false,
                password_confirmation: false,
            },
        }
    },
    methods: {
        submit() {
            if (this.isSubmitting) return;

            this.isSubmitting = true
            auth.accountSignup(this.formData)
                .then(() => this.onSignupSuccess())
                .catch(() => this.onSignupFailure())
                .finally(() => { this.isSubmitting = false })
        },
        onSignupSuccess() {
            this.$emit('success')
        },
        onSignupFailure() {
            this.$_toasterDanger('Submission failed. Please try again.')

            const { password, password_confirmation, ...cleanFormData } = this.formData; //eslint-disable-line
            this.$emit('step', cleanFormData, 0)
        },
        togglePasswordReveal(inputName) {
            this.revealedPasswordInputs[inputName] = !this.revealedPasswordInputs[inputName]
        },
        isPasswordRevealed(inputName) {
            return this.revealedPasswordInputs[inputName]
        },
    },
};
</script>

<style lang="scss" scoped>
.login-prompt {
    color: var(--brandSecondary)
}

.google-login {
    &__btn {
        width: 100%;
        border: 1px solid #d9dbf1;
        background-color: white;
        border-radius: 25px;
        color: #aba5af;
        padding-inline: 1rem;

        &:hover {
            background-color: darken(white, 5%);
        }
    }

    &__logo {
        width: 2rem;
        height: 2rem;
    }
}

.input-group {
    display: flex;
    align-items: center;
}

.reveal-password {
    margin-left: -1px;
    display: flex;
    align-items: center;
}

.input-group .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.reveal-password .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    border-color: var(--textInputBorderColor);
}
</style>
